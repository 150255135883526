export default defineNuxtRouteMiddleware(async () => {
  const session = await getUserSession()
  useState("userDataType", () => {
    enum DataType {
      KB = "KB",
      MB = "MB",
      GB = "GB"
    }

    const userDataType = session?.user?.dataType
    const dataSizes = {
      [DataType.KB]: BigInt(1024),
      [DataType.MB]: BigInt(1048576),
      [DataType.GB]: BigInt(1073741824)
    }

    // TODO - make this account wide
    let division = {}
    if (userDataType) {
      division = dataSizes[userDataType]
    }

    return {
      displayName: userDataType,
      division
    }
  })
})
